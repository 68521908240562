/* eslint-disable react/no-unescaped-entities */
import {
  RiComputerLine,
  BiPrinterFill,
  IcRoundEquals,
  GameIconsRolledCloth,
  RiSave3Fill,
  RiEqualizerFill,
  MingcuteColorPickerFill,
  BxBxsNetworkChart,
  TablerArrowsRightLeft,
  Fa6SolidShieldHalved,
  MajesticonsColorSwatch,
  BxBxsMagicWand,
  Tabler3dCubeSphere,
  BiLightningChargeFill,
  MajesticonsAcademicCap,
  PhDotsNineBold,
  CarbonDataBackup,
  LaHammer,
} from '../data/svgIcons/all-svg-icons'

const svgData = {
  color: 'orange',
  width: 20,
  height: 20,
}

export const en = {
  navbar: [
    {
      name: 'About Us',
      link: '/about',
    },
    {
      name: 'Contact',
      link: '/contact',
    },
  ],
  header: {
    title: (
      <h1 className='title py-2'>
        The fastest coloring
        <br className='hidden sm:inline' /> software ever created.
      </h1>
    ),
    subtitle: (
      <p className='mt-2 text-xl xl:text-2xl text-neutral-400'>
        Meet the best design coloring software.{' '}
        <br className='hidden sm:inline' /> All you need is in one package.
      </p>
    ),
    button: <p className='drop-shadow'>Get started today</p>,
  },
  companies: {
    title: 'Loved by the best',
    subtitle: (
      <p className='mt-2 text-lg xl:text-xl text-neutral-400'>
        Preferred by the leading companies of the{' '}
        <br className='hidden sm:inline' /> textile industry and proved itself.
      </p>
    ),
  },
  video: {
    titles: [
      <h3 key={1} className='text-2xl sm:text-3xl md:text-4xl font-bold text-gray-100'>
        Find the design you're
        <br className='hidden sm:inline not-sr-only' /> looking for{' '}
        within seconds.
      </h3>,
      <h3 key={2} className='text-2xl sm:text-3xl md:text-4xl font-bold text-gray-100'>
        Design colors in an{' '}
        RGB design while{' '}
        maintaining the color harmony.
      </h3>,
    ],
    descriptions: [
      <p key={3} className='mt-3  text-neutral-300'>
        {' '}
        With the fully integrated archive system, finding the design you're
        looking for is very easy. Designs can be found using fields like{' '}
        <span className='text-orange-400'>Design Number</span>,{' '}
        <span className='text-orange-400'>Label</span>,{' '}
        <span className='text-orange-400'>Customer</span>,{' '}
        <span className='text-orange-400'>Screen Status</span> and
        <span className='text-orange-400'> Designer</span> and can be sorted
        using fields like <span className='text-orange-400'>Design Number</span>
        , <span className='text-orange-400'>Date Uploaded</span>, and{' '}
        <span className='text-orange-400'>Date Edited</span>. Without having to
        open the designs, you can see all of the information and browse through
        the colorways.
      </p>,
      <p key={4} className='mt-3  text-neutral-300'>
        ColorwayXpress can color your{' '}
        <span className='text-fuchsia-400'>RGB (flattened)</span> or
        <span className='text-fuchsia-400'> multi-layered</span> designs without
        disturbing the color harmony and the effects on the design. With the
        algorithms specially designed for textiles, ColorwayXpress can color any design
        easily. You can assign a color to an effected area from Pantone, color
        it separately one by one into tones, or assign a color from the Color
        Picker. In addition, combine dozens of effects like{' '}
        <span className='text-fuchsia-400'>Hue Saturation</span>,
        <span className='text-fuchsia-400'> Selective Color </span> and
        <span className='text-fuchsia-400'> Gradient Map </span>
        to create amazing colorways.
      </p>,
    ],
  },
  features: {
    title: 'Which features do we offer?',
    description: (
      <p className='mt-2  text-lg xl:text-xl text-neutral-400'>
        ColorwayXpress is a software preferred by all textile printing factories with its features that meet all the needs of the industry.<br className='hidden sm:inline not-sr-only' />
        Artificial Intelligence Features, Screen Calibration, Archive System, Automatic Colorway Generation,<br className='hidden sm:inline not-sr-only' />CAD Pages, and many other features make it indispensable for users.
      </p>
    ),
    featuresList: [
      {
        id: '01',
        title: 'Color Calibration',
        description:
          'Color samples from your digital printings are imported to ColorwayXpress via the enhanced ICC Profile system, creating a unique printing simulation. It reduces your test prints by up to 90% by matching the screen and fabric.',
        link: '',
        icon: [
          <RiComputerLine
            color={svgData.color}
            width={svgData.width}
            height={svgData.height}
            key='01'
          />,
          <IcRoundEquals
            color={svgData.color}
            width={svgData.width}
            height={svgData.height}
            key='2'
          />,
          <BiPrinterFill
            color={svgData.color}
            width={svgData.width}
            height={svgData.height}
            key='3'
          />,
          <IcRoundEquals
            color={svgData.color}
            width={svgData.width}
            height={svgData.height}
            key='4'
          />,
          <GameIconsRolledCloth
            color={svgData.color}
            width={svgData.width}
            height={svgData.height}
            key='5'
          />,
        ],
      },
      {
        id: '02',
        title: 'Conventional Design Coloring',
        description:
          'You can quickly color all your multi-channel designs suitable for conventional printing. You can use the color catalogs, see the coverage percentages of the channels, add fabric color or view the entire design with screening simulation.',
        link: '',
        icon: [
          <MingcuteColorPickerFill
            color={svgData.color}
            width={svgData.width}
            height={svgData.height}
            key='6'
          />,
        ],
      },
      {
        id: '03',
        title: 'Conventional Simulation',
        description:
          'You can take samples of conventional designs in fabric or paper printing in screened (dotted) simulation, and you can simulate the spread of the ink on the fabric.',
        link: '',
        icon: [
          <PhDotsNineBold
            color={svgData.color}
            width={svgData.width}
            height={svgData.height}
            key='7'
          />,
        ],
      },
      {
        id: '04',
        title: 'Storage Saving Colorways',
        description:
          'The file sizes of your colorways are not a problem for you. With the algorithms we have specially developed, the colorways created with ColorwayXpress do not take up space, they save up to 90% compared to other softwares.',
        link: '',
        icon: [
          <RiSave3Fill
            color={svgData.color}
            width={svgData.width}
            height={svgData.height}
            key='8'
          />,
        ],
      },
      {
        id: '05',
        title: 'Centralized Work',
        description:
          "All users work with a shared archive system. Users can see each other's work instantly. Users do not need to download designs or colorways to work, Therefore, data flow issues and file transfer delays are avoided.",
        link: '',
        icon: [
          <BxBxsNetworkChart
            color={svgData.color}
            width={svgData.width}
            height={svgData.height}
            key='9'
          />,
        ],
      },
      {
        id: '06',
        title: 'Custom CAD Templates',
        description:
          'Creating CAD printouts is now very easy with the layouts tailored to your business. Choose a layout, select colorways, and leave the rest to ColorwayXpress.',
        link: '',
        icon: [
          <RiEqualizerFill
            color={svgData.color}
            width={svgData.width}
            height={svgData.height}
            key='10'
          />,
        ],
      },
      {
        id: '07',
        title: 'Colorway Transfer',
        description:
          "Quickly transfer favorite colorways to different designs. Colorways can be transferred between designs with the same colors but different motifs and sizes. It's also as simple as copy-paste.",
        link: '',
        icon: [
          <TablerArrowsRightLeft
            color={svgData.color}
            width={svgData.width}
            height={svgData.height}
            key='11'
          />,
        ],
      },
      {
        id: '08',
        title: 'Design – Colorway Repair',
        description:
          'All colorways can be corrected with a single file editing operation if duplication or motif errors are discovered after the colorways are created. Without having to correct all colorways one by one, design repair can be done quickly.',
        link: '',
        icon: [
          <LaHammer
            color={svgData.color}
            width={svgData.width}
            height={svgData.height}
            key='12'
          />,
        ],
      },
      {
        id: '09',
        title: 'Automatic Colorway',
        description:
          "In seconds, create up to 250 automatic colorways of digital and conventional designs while maintaining the design's structure and color transition. Choose your favorites or keep modifying them. Use your client's colors, Pantone, or Coloro catalogs to create wonders!",
        link: '',
        icon: [
          <BxBxsMagicWand
            color={svgData.color}
            width={svgData.width}
            height={svgData.height}
            key='13'
          />,
        ],
      },
      {
        id: '10',
        title: 'Color Catalogs',
        description:
          'All colors are at hand with latest color catalogues. New trend color catalogs are added with updates, in addition to the current Pantone(R) and Coloro(R) colors available in ColorwayXpress. You can quickly and easily create customer color catalogs or your own color catalogs using these catalogs.',
        link: '',
        icon: [
          <MajesticonsColorSwatch
            color={svgData.color}
            width={svgData.width}
            height={svgData.height}
            key='14'
          />,
        ],
      },
      {
        id: '11',
        title: 'ERP Integration',
        description:
          'ColorwayXpress can exchange information, send data, and process incoming data with any ERP software. It adapts to the current flow of your company and does not need an additional budget.',
        link: '',
        icon: [
          <Tabler3dCubeSphere
            color={svgData.color}
            width={svgData.width}
            height={svgData.height}
            key='15'
          />,
        ],
      },
      {
        id: '12',
        title: 'Copy Protection',
        description:
          'All designs are protected by special algorithms and passwords. Even if the designs are tried to be used without permission, they will not be able to be opened in ColorwayXpress, which is used by a variety of businesses.',
        link: '',
        icon: [
          <Fa6SolidShieldHalved
            color={svgData.color}
            width={svgData.width}
            height={svgData.height}
            key='16'
          />,
        ],
      },
      {
        id: '13',
        title: 'Always Redundant',
        description:
          'All designs and colorways are saved by the backup system. All deleted, repaired, or changed designs and colorways are automatically backed up on your servers. Backups cannot be deleted from ColorwayXpress. Even deleted designs and colorways can be retrieved this way.',
        link: '',
        icon: [
          <CarbonDataBackup
            color={svgData.color}
            width={svgData.width}
            height={svgData.height}
            key='17'
          />,
        ],
      },
      {
        id: '14',
        title: 'Fast',
        description:
          'ColorwayXpress uses different algorithms than other programs to open designs and colorways. With the developed extraordinary algorithms, even though the file sizes are very large, operations such as opening designs, navigating through designs, working on colorways and saving these works can be done in seconds.',
        link: '',
        icon: [
          <BiLightningChargeFill
            color={svgData.color}
            width={svgData.width}
            height={svgData.height}
            key='18'
          />,
        ],
      },
      {
        id: '15',
        title: 'Academy',
        description:
          "Meet ColorwayXpress Academy, where new content is added and updated on a regular basis. Here you can find instructions for using all of ColorwayXpress's features. Learn how to do everything and how to use all of the features available to you. Moreover, with both text and video narrations.",
        link: '',
        icon: [
          <MajesticonsAcademicCap
            color={svgData.color}
            width={svgData.width}
            height={svgData.height}
            key='19'
          />,
        ],
      },
    ],
  },
  footer: {
    description: (
      <p className='mt-1 text-sm'>
        DoCode brought a new breath to the textile industry{' '}
        <br className='hidden sm:inline' />
        with the softwares and extraordinary algorithms it developed for the textile industry.
      </p>
    ),
    copyRight: (
      <p className='text-xs'>
        Copyright © 2024 DoCode Limited.
        <br className='sm:hidden inline' /> All rights reserved.
      </p>
    ),
  },
  about: {
    title: 'About Us',
    description:
      'Our company, Docode, was established on April 5, 2016 in Bursa. Our company, which has contributed to the World Textile Industry with the experience we have gained from our users, who have preferred us from all levels and sizes for years, provides services in the fields of Design Coloring Software, Screen Calibration and Online Design Archive.',
    description2:
      'DoCode was accepted to Ulutek Technology Development Zone in 2019 with its domestic software and extraordinary algorithms. It provides uninterrupted service to the best in the industry with its constantly growing expert software developer and designer staff.',
    visionTitle: 'Our Vision',
    visionDescription:
      'To make ColorwayXpress Coloring Software and eColorway Online Archive Service, which are the extraordinary products we produce, indispensable for the World Textile Industry.',
    missionTitle: 'Our Mission',
    missionDescription: 'Within the scope of this vision of DoCode;',
    missionList: [
      {
        id: '01',
        mark: 'Cooperates with the best software and hardware manufacturers in the field,',
      },
      {
        id: '02',
        mark: 'It incorporates all instruments to serve users of all sizes,',
      },
      {
        id: '03',
        mark: 'Monitors all systems 24/7 in order to provide uninterrupted service to its customers,',
      },

      {
        id: '04',
        mark: 'It has personnel trained in the field,',
      },

      {
        id: '05',
        mark: 'Gives importance to education and development, follows international technological innovations,',
      },

      {
        id: '06',
        mark: 'It provides its customers with the best technical support at the most extreme point possible.',
      },
    ],
  },
  contact: {
    title: 'Send us a message',
    description: (
      <p className='mt-2'>
        Please fill out the form below to contact us.{' '}
        <br className='hidden sm:inline' /> We will get back to you as soon as
        possible.
      </p>
    ),
    subject: 'Subject',
    subjectMax: 'Min 100 characters',
    name: 'First name',
    lastName: 'Last name',
    email: 'Email',
    phone: 'Phone',
    phoneOptional: 'Optional',
    message: 'Message',
    messageMax: 'Min 150 / Max 500 characters',
    submit: 'Send',
    success: 'Your message has been sent successfully.',
    error: 'An error occurred while sending your message.',
  },
  buttons: {
    readMore: 'Read More',
    showMore: 'Show More',
  },
  seo: {
    title: 'ColorwayXpress',
    titleDescription: 'Textile Design Coloring Software. Create Colorways In Seconds',
    description:
      'Meet the ColorwayXpress, the fastest textile design coloring software. Create colorways to RGB, multi-layered and multi-channeled designs in seconds.',
    keywords:
      'ColorwayXpress, design coloring software, colorway software, textile design software, fashion design software, colorways software, Colorway Express, fashion colorway, textile design colorway software',
  },
}
