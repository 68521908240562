// /* eslint-disable react/jsx-no-undef */
// import Navbar from './navbar'
// import Footer from './footer'
import { useRouter } from 'next/router'
import { en } from '../languageList/en.jsx'
import { tr } from '../languageList/tr.jsx'
import { LanguageContext } from '../hook/context'
import { DefaultSeo } from 'next-seo'
import dynamic from 'next/dynamic'

const Navbar = dynamic(() => import('./navbar'))
const Footer = dynamic(() => import('./footer'))

export default function Layout({ children }) {
  const router = useRouter()

  const { locale } = router
  const t = locale === 'en' ? en : tr

  return (
    <>
      <LanguageContext.Provider value={{ t }}>
        <DefaultSeo
          description={t.seo.description}
          openGraph={{
            type: 'website',
            locale: 'tr',
            url: 'https://www.colorwayxpress.com',
            site_name: 'ColorwayXpress',
          }}
          languageAlternates={[
            {
              hrefLang: 'tr',
              href: 'https://www.colorwayxpress.com/tr',
            },
            {
              hrefLang: 'en',
              href: 'https://www.colorwayxpress.com/en',
            },
          ]}
          title={t.seo.titleDescription}
          titleTemplate='ColorwayXpress | %s'
          additionalMetaTags={[
            {
              name: 'keywords',
              content: t.seo.keywords,
            },
          ]}
        />
        <Navbar />
        <div className='mx-auto px-5 relative mb-10   md:mt-32 mt-20 max-w-[1400px]'>
          <main>{children}</main>
          <Footer />
        </div>
      </LanguageContext.Provider>
    </>
  )
}
