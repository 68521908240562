/* eslint-disable react/no-unescaped-entities */
import {
  RiComputerLine,
  BiPrinterFill,
  IcRoundEquals,
  GameIconsRolledCloth,
  RiSave3Fill,
  RiEqualizerFill,
  MingcuteColorPickerFill,
  BxBxsNetworkChart,
  TablerArrowsRightLeft,
  Fa6SolidShieldHalved,
  MajesticonsColorSwatch,
  BxBxsMagicWand,
  Tabler3dCubeSphere,
  BiLightningChargeFill,
  MajesticonsAcademicCap,
  PhDotsNineBold,
  CarbonDataBackup,
  LaHammer,
} from '../data/svgIcons/all-svg-icons'

const svgData = {
  color: 'orange',
  width: 20,
  height: 20,
}

export const tr = {
  navbar: [
    {
      name: 'Hakkımızda',
      link: '/about',
    },
    {
      name: 'İletişim',
      link: '/contact',
    },
  ],
  header: {
    title: (
      <h1 className='title py-2'>
        Şimdiye kadar geliştirilmiş en iyi
        <br className='hidden sm:inline' /> varyant programı
      </h1>
    ),
    subtitle: (
      <p className='mt-2 text-xl xl:text-2xl text-neutral-400'>
        Üretilmiş en iyi varyant yazılımıyla tanışın.{' '}
        <br className='hidden sm:inline' /> Üstelik her şey tek bir pakette.
      </p>
    ),
    button: <p className='drop-shadow'>Bugün kullanmaya başlayın</p>,
  },
  companies: {
    title: 'En iyilerin tercihi',
    subtitle: (
      <p className='mt-2 text-lg xl:text-xl text-neutral-400'>
        Tekstil sektörünün en iyileri tarafından{' '}
        <br className='hidden sm:inline' /> tercih edildi ve kendini kanıtladı.
      </p>
    ),
  },
  video: {
    titles: [
      <h3 key={1} className='text-2xl sm:text-3xl md:text-4xl font-bold text-gray-100'>
        Aradığınız deseni
        <br className='hidden sm:inline not-sr-only' />{' '}
        saniyeler içerisinde{' '}
        bulun.
      </h3>,
      <h3 key={2} className='text-2xl sm:text-3xl md:text-4xl font-bold text-gray-100'>
        RGB desen renklendirme{' '}
        ile renk uyumunu bozmadan renklendirin.
      </h3>,
    ],
    descriptions: [
      <p key={3} className='mt-3  text-neutral-300'>
        {' '}
        Tam entegre arşiv sistemi ile aradığınız deseni bulmak çok kolay.{' '}
        <span className='text-orange-400'>Desen Numarası</span>,{' '}
        <span className='text-orange-400'>Etiket</span>,{' '}
        <span className='text-orange-400'>Müşteri</span>,{' '}
        <span className='text-orange-400'>Kalıp Durumu</span> ve
        <span className='text-orange-400'> Desinatör </span> gibi alanlarla
        desenleri arayabilir,{' '}
        <span className='text-orange-400'>Desen Numarası</span>,{' '}
        <span className='text-orange-400'>Yüklenme Tarihi</span> ve{' '}
        <span className='text-orange-400'>Düzenlenme Tarihi </span>
        gibi alanlarla sıralayabilirsiniz. Desenleri açmanıza gerek kalmadan tüm
        bilgilerini görebilir, varyantları arasında gezebilirsiniz.
      </p>,
      <p key={4} className='mt-3  text-neutral-300'>
        <span className='text-fuchsia-400'>RGB</span> veya{' '}
        <span className='text-fuchsia-400'>layerli</span> desenlerinizi renk
        uyumunu ve desendeki efektleri bozmadan renklendirebilir. Tekstil için
        özel olarak tasarlanmış algoritmalar sayesinde her türlü deseni
        zorlanmadan renklendirebilir. Efektli bir alana Pantone’den renk
        atayabilir, tonlarına ayırarak ayrı ayrı renklendirebilir veya Renk
        Seçici’den renk atayabilirsiniz. Bunlara ek olarak,{' '}
        <span className='text-fuchsia-400'>Hue Saturation</span>,
        <span className='text-fuchsia-400'> Selective Color</span> ve{' '}
        <span className='text-fuchsia-400'>Gradient Map</span> gibi onlarca
        efekti birleştirerek harikalar yaratın.
      </p>,
    ],
  },
  features: {
    title: 'Hangi özellikleri sunuyoruz?',
    description: (
      <p className='mt-2 text-lg xl:text-xl text-neutral-400'>
        ColorwayXpress, sektörün tüm ihtiyaçlarını karşılayan özellikleriyle tüm tekstil baskı fabrikalarının tercih ettiği bir yazılımdır.<br className='hidden sm:inline not-sr-only' />
        Yapay Zeka Özellikleri, Ekran Kalibrasyonu, Arşiv Sistemi, Otomatik Varyantlama,<br className='hidden sm:inline not-sr-only' /> CAD Sistemi gibi bir çok özellik onu, kullanıcıların vazgeçilmezi yapıyor.

        {/* ColorwayXpress, moda ve tekstil şirketleri için renkleri yönetmek ve
        daha iyi <br className='hidden sm:inline not-sr-only' /> renkler oluşturmak için
        lider bir yazılımdır. Gelişmiş özellikleri ve esnek iş akışları,
        <br className='hidden lg:inline not-sr-only' /> onu ekipler için en iyi seçenek
        haline getiriyor renk yönetimini optimize etmek istiyor. */}
      </p>
    ),
    featuresList: [
      {
        id: '01',
        title: 'Renk Kalibrasyonu',
        description:
          'Geliştirilmiş ICC Profil sistemiyle, dijital baskı makinelerinizden alınan renk örnekleri ColorwayXpress’e yüklenerek benzersiz bir baskı simülasyonu oluşturur. 90%’a varan Ekran-Kumaş eşleşmesi sağlayarak deneme baskılarınızı minimuma indirir.',
        link: '',
        icon: [
          <RiComputerLine
            color={svgData.color}
            width={svgData.width}
            height={svgData.height}
            key='01'
          />,
          <IcRoundEquals
            color={svgData.color}
            width={svgData.width}
            height={svgData.height}
            key='2'
          />,
          <BiPrinterFill
            color={svgData.color}
            width={svgData.width}
            height={svgData.height}
            key='3'
          />,
          <IcRoundEquals
            color={svgData.color}
            width={svgData.width}
            height={svgData.height}
            key='4'
          />,
          <GameIconsRolledCloth
            color={svgData.color}
            width={svgData.width}
            height={svgData.height}
            key='5'
          />,
        ],
      },
      {
        id: '02',
        title: 'Konvansiyonel Desen Renklendirme',
        description:
          'Konvansiyonel baskıya uygun tüm kanallı desenlerinizi hızlıca renklendirebilirsiniz. Renk kataloglarını kullanabilir, kanalların doluluk oranlarını görebilir, kumaş rengi ekleyebilir veya tüm deseni tram simülasyonuyla görüntüleyebilirsiniz.',
        link: '',
        icon: [
          <MingcuteColorPickerFill
            color={svgData.color}
            width={svgData.width}
            height={svgData.height}
            key='6'
          />,
        ],
      },
      {
        id: '03',
        title: 'Tram Simülasyonu',
        description:
          'Konvansiyonel desenlerin dijital ya da kağıt baskıda numunesini tramlı halde alabilir, lap (düz, geçişsiz) desenlerde boyanın yayılmasını ve oluşan karışım renklerini simüle edebilirsiniz.        ',
        link: '',
        icon: [
          <PhDotsNineBold
            color={svgData.color}
            width={svgData.width}
            height={svgData.height}
            key='7'
          />,
        ],
      },
      {
        id: '04',
        title: 'Yer Kaplamayan Varyantlar',
        description:
          'Varyantlarınızın dosya boyutları sizin için bir problem değil. Özel olarak geliştirdiğimiz algoritmalar sayesinde ColorwayXpress’te alınan varyantlar yer kaplamaz, diğer programlara göre %90’a varan yer tasarrufu sağlar.        ',
        link: '',
        icon: [
          <RiSave3Fill
            color={svgData.color}
            width={svgData.width}
            height={svgData.height}
            key='8'
          />,
        ],
      },
      {
        id: '05',
        title: 'Merkezi Çalışma',
        description:
          'Tüm kullanıcılar ortak arşiv sistemi ile çalışırlar. Kullanıcılar birbirlerinin çalışmalarını anlık olarak görebilirler. Kullanıcılar çalışmak için desen ya da varyantları indirmek zorunda kalmaz ve bu sayede veri akışı sorunları ya da dosya transferleri için beklemek gibi durumlar oluşmaz.',
        link: '',
        icon: [
          <BxBxsNetworkChart
            color={svgData.color}
            width={svgData.width}
            height={svgData.height}
            key='9'
          />,
        ],
      },
      {
        id: '06',
        title: 'Özel CAD Tasarımları',
        description:
          'Firmanıza özel olarak hazırlanan şablonlarla CAD çıktılar oluşturmak artık çok kolay. Bir şablon seçin, varyantları belirleyin ve bırakın gerisini ColorwayXpress yapsın.',
        link: '',
        icon: [
          <RiEqualizerFill
            color={svgData.color}
            width={svgData.width}
            height={svgData.height}
            key='10'
          />,
        ],
      },
      {
        id: '07',
        title: 'Varyant Taşıma',
        description:
          'Beğenilen varyantları farklı desenlere hızlıca taşıyın. Aynı renklere sahip farklı motif ve ölçülerden oluşan desenler arasında varyantları taşıyabilirsiniz. Üstelik kopyala-yapıştır kadar kolay!',
        link: '',
        icon: [
          <TablerArrowsRightLeft
            color={svgData.color}
            width={svgData.width}
            height={svgData.height}
            key='11'
          />,
        ],
      },
      {
        id: '08',
        title: 'Desen - Varyant Onarımı',
        description:
          'Varyantlar yapıldıktan sonra fark edilen teksir veya motif hatalarında, tek bir dosya düzenleme işlemi ile tüm varyantlar düzeltilebilir. Tüm varyantları tek tek düzeltmeye gerek kalmadan hızlıca desen onarımı yapılabilir.',
        link: '',
        icon: [
          <LaHammer
            color={svgData.color}
            width={svgData.width}
            height={svgData.height}
            key='12'
          />,
        ],
      },
      {
        id: '09',
        title: 'Otomatik Varyant',
        description:
          'Desenin yapısını ve renk geçişini koruyarak hem dijital hem de konvansiyonel desenlere saniyeler içerisinde 250 adede kadar otomatik varyantlar oluşturun. Beğendiklerinizi seçin veya üzerlerinde değişiklik yapmaya devam edin. Müşteri renklerinizi, Pantone veya Coloro kataloglarını kullanarak harikalar yaratın!',
        link: '',
        icon: [
          <BxBxsMagicWand
            color={svgData.color}
            width={svgData.width}
            height={svgData.height}
            key='13'
          />,
        ],
      },
      {
        id: '10',
        title: 'Renk Katalogları',
        description:
          'Güncel renk katalogları ile tüm renkler elinizin altında. ColorwayXpress’te bulunan güncel Pantone(R) ve Coloro(R) renklerinin yanı sıra yeni çıkan trend renk katalogları da güncellemeler ile eklenir. Bu kataloglar ile birlikte, müşteri renk kataloglarını veya kendi renk kataloglarınızı hızlı ve kolay bir şekilde oluşturabilirsiniz.',
        link: '',
        icon: [
          <MajesticonsColorSwatch
            color={svgData.color}
            width={svgData.width}
            height={svgData.height}
            key='14'
          />,
        ],
      },
      {
        id: '11',
        title: 'ERP Entegrasyonu',
        description:
          'ColorwayXpress tüm ERP yazılımlarıyla haberleşebilir, veri gönderebilir ve gelen veriyi işleyebilir. Firmanızdaki güncel akışa uyum sağlar, ek yük yaratmaz.',
        link: '',
        icon: [
          <Tabler3dCubeSphere
            color={svgData.color}
            width={svgData.width}
            height={svgData.height}
            key='15'
          />,
        ],
      },
      {
        id: '12',
        title: 'Kopya Koruması',
        description:
          'Tüm desenler özel algoritmalarla ve şifrelerle korunur. Bu sayede desenler izinsiz alınsa bile farklı firmalarda kullanılan ColorwayXpress’te dahi açılamaz.',
        link: '',
        icon: [
          <Fa6SolidShieldHalved
            color={svgData.color}
            width={svgData.width}
            height={svgData.height}
            key='16'
          />,
        ],
      },
      {
        id: '13',
        title: 'Silinmez, Yedekli Kalır',
        description:
          'Yedekleme sistemi tüm desen ve varyantları korur. Silinen, onarılan ya da değiştirilen tüm desenler ve varyantlar arka planda sizin sunucularınızda yedeklenir. Yedekler ColorwayXpress’ten silinemez. Bu sayede, silinen desenler ve varyantlar dahi getirilebilir.',
        link: '',
        icon: [
          <CarbonDataBackup
            color={svgData.color}
            width={svgData.width}
            height={svgData.height}
            key='17'
          />,
        ],
      },
      {
        id: '14',
        title: 'Hızlı',
        description:
          'ColorwayXpress, desen ve varyantları diğer programlardan farklı algoritmalar ile açar. Geliştirilen sıradışı algoritmalar sayesinde dosya boyutları çok büyük olsa da desen açma, desenler arasında gezme, varyantlar üzerinde çalışma ve bu çalışmaları kaydetme gibi işlemler saniyeler içinde yapılabilir.',
        link: '',
        icon: [
          <BiLightningChargeFill
            color={svgData.color}
            width={svgData.width}
            height={svgData.height}
            key='18'
          />,
        ],
      },
      {
        id: '15',
        title: 'Akademi',
        description:
          'Güncellemelerle birlikte sürekli yeni içerikler eklenen ColorwayXpress Akademi ile tanışın. ColorwayXpress üzerinde bulunan tüm özelliklerin kullanım anlatımları burada. Her şeyin nasıl yapıldığını ve elinizin altındaki tüm özelliklerin nasıl kullanıldığını öğrenin. Üstelik hem metin hem de videolu anlatımlarıyla.',
        link: '',
        icon: [
          <MajesticonsAcademicCap
            color={svgData.color}
            width={svgData.width}
            height={svgData.height}
            key='19'
          />,
        ],
      },
    ],
  },
  footer: {
    description: (
      <p className='mt-1 text-sm'>
        DoCode, tekstil sektörü için geliştirdiği{' '}
        <br className='hidden sm:inline' /> yazılımlar ve sıra dışı algoritmalarıyla ile sektöre yeni bir
        soluk getirmiştir.
      </p>
    ),
    copyRight: (
      <p className='text-xs'>
        Copyright © 2024 DoCode Limited.
        <br className='sm:hidden inline' /> Her Hakkı Saklıdır.
      </p>
    ),
  },
  about: {
    title: 'Hakkımızda',
    description:
      "Şirketimiz Docode, 5 Nisan 2016 yılında Bursa’da kuruldu. Yıllardır her seviyeden, her ölçekten bizi tercih eden kullanıcılarımızdan edindiğimiz tecrübe ile Dünya Tekstil Sektörü'ne katkı sağlayan şirketimiz, Varyant Yazılımı, Ekran Kalibrasyonu ve Online Desen Arşivi alanlarında hizmet sağlar.",
    description2:
      'DoCode, üretmiş olduğu yerli yazılımlar ve sıra dışı algoritmalarla 2019 yılında Ulutek Teknoloji Geliştirme Bölgesi`ne kabul edilmiştir. Sürekli büyüyen uzman yazılımcı ve eğitmen desinatör kadrosuyla sektörün en iyilerine kesintisiz hizmet vermektedir.',
    visionTitle: 'Vizyonumuz',
    visionDescription:
      "Üretmiş olduğumuz sıra dışı ürünler olan ColorwayXpress Varyant Yazılımı ve eColorway Online Arşiv Hizmeti'ni Dünya Tekstil Sektörü'nün vazgeçilmezi yapmaktır.",
    missionTitle: 'Misyonumuz',
    missionDescription: 'DoCode bu vizyonu kapsamında;',
    missionList: [
      {
        id: '01',
        mark: 'Alanında en iyi yazılım ve donanım üreticileri ile işbirliği yapar,',
      },
      {
        id: '02',
        mark: 'Her ölçekte kullanıcıya hizmet vermek için tüm enstrümanları bünyesinde barındırır,',
      },
      {
        id: '03',
        mark: 'Müşterilerine kesintisiz hizmet verebilmek için tüm sistemleri 7/24 monitör eder,',
      },

      {
        id: '04',
        mark: 'Bünyesinde alanında eğitim görmüş personeli barındırır,',
      },

      {
        id: '05',
        mark: 'Eğitime ve gelişime önem verir, Uluslararası teknolojik yenilikleri takip eder,',
      },

      {
        id: '06',
        mark: 'Müşterilerine en iyi teknik desteği, olabilen en uç noktada sağlar',
      },
    ],
  },
  contact: {
    title: 'Bize Ulaşın',
    description: (
      <p className='mt-2'>
        Lütfen aşağıdaki formu doldurarak bizimle iletişim kurun.{' '}
        <br className='hidden sm:inline' /> En kısa sürede geri dönüş yapacağız.
      </p>
    ),
    subject: 'Konu',
    subjectMax: 'En az 100 karakter',
    name: 'İsim',
    lastName: 'Soyisim',
    email: 'Email',
    phone: 'Telefon',
    phoneOptional: 'İsteğe bağlı',
    message: 'Mesaj',
    messageMax: 'En az 150 / En fazla 500 karakter',
    submit: 'Gönder',
    success: 'Mesajınız başarıyla gönderildi.',
    error: 'Mesajınız gönderilemedi. Lütfen daha sonra tekrar deneyin.',
  },
  buttons: {
    readMore: 'Devamını Oku',
    showMore: 'Daha Fazla Göster',
  },
  seo: {
    title: 'ColorwayXpress',
    titleDescription: 'Tekstil Desen Varyant Programı',
    description:
      'En hızlı varyant yazılımı ColorwayXpress ile tanışın. RGB, Layerli veya kanallı desenlere hızlıca varyantlar alın.',
    keywords:
      'ColorwayXpress, varyant yazılımı, desen varyant, desen renklendirme programı, varyant programı, colorway express, colorway programı',
  },
}
